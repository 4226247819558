<template>
  <div class="details-block">
    <div class="title_with_popup">
      <div class="title_with_popup__popup-wrapper">
        {{ $t('new_checkout.details.title') }}
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"
             @mouseover="showPopup = true"
             @mouseleave="showPopup = false">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM8.5 5.5C9.05229 5.5 9.5 5.05228 9.5 4.5C9.5 3.94772 9.05229 3.5 8.5 3.5C7.94772 3.5 7.5 3.94772 7.5 4.5C7.5 5.05228 7.94772 5.5 8.5 5.5ZM7.13969 6.875L9.4307 6.58789L8.42875 11.293C8.35844 11.6328 8.45805 11.8262 8.73344 11.8262C8.9268 11.8262 9.21977 11.7559 9.41898 11.5801L9.33109 11.9961C9.04398 12.3418 8.41117 12.5938 7.86625 12.5938C7.16313 12.5938 6.8643 12.1719 7.05766 11.2754L7.79594 7.80664C7.86039 7.51367 7.8018 7.4082 7.50883 7.33789L7.05766 7.25586L7.13969 6.875Z" fill="var(--text-color-text-secondary)"/>
        </svg>
        <div v-if="showPopup" class="title_with_popup__popup">
          <div v-if="orderDescription" class="organization__description">
            <p class="organization__description-title">{{ $t("new_checkout.details.description") }}:</p>
            <p class="organization__description-text">{{ orderDescription }}</p>
          </div>
          <div class="organization__data">
            <p class="organization__description-title">{{ $t("new_checkout.details.date") }}:</p>
            <p class="organization__description-text">{{ date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFormatDateTime
} from "@/entries/checkout/filters";

export default {
  name: "Details",
  components: {
    ClipboardCopy: () => import(/* webpackChunkName: "/entries/checkout/invoice/ClipboardCopy" */ '@/entries/invoice/components/ClipboardCopy'),
  },
  data() {
    return {
      showPopup: false
    }
  },
  props: {
    orderAmount: {
      type: String,
      default: ''
    },
    orderAmountUSD: {
      type: String,
      default: ''
    },
    orderId: {
      type: String,
      default: ''
    },
    orderDescription: {
      type: String,
      default: ''
    },
    orderCurrency: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    organizationName: {
      type: String,
      default: undefined
    }
  },
  computed: {
    prettyAmountUSD({ orderAmountUSD }) {
      return orderAmountUSD && Number(orderAmountUSD).toFixed(2)
    }
  },
}
</script>

<style lang="scss">
 .details-block {
   display: none;
   .title_with_popup {
     &__title {
       color: var(--text-text-primary);
       font-size: var(--font-size-big);
     }
     &__popup-wrapper {
       display: flex;
       align-items: center;
       gap: 5px;

       position: relative;

       color: var(--text-color-text-secondary);
       font-size: 16px;
       font-weight: 600;

       .icon {
         cursor: pointer;
         margin-left: 5px;
       }
     }
     &__popup {
       padding: 15px 20px;
       background: var(--bg-background-neutral, #FFF);

       border-radius: 10px;
       border: 1px solid var(--border-border-neutral-variant, #EEE);


       position: absolute;
       z-index: 1;
       top: 30px;
       min-width: 250px;

       .row {
         padding: 8px 0;
         .label {
           color: var(--text-text-primary);
         }
         .value {
           color: var(--text-color-text-secondary);
         }
       }
     }

     @include below_mobile {
       display: flex;
       justify-content: space-between;

       &__popup {
         left: 0;
       }
     }
   }

   &__amount-raw {
     display: flex;
     align-items: center;

     font-size: var(--font-size-extra);

     .amount {
       color: var(--text-text-primary);
     }
     .currency {
       color: var(--text-color-text-secondary);
       margin: 0 8px;
     }
   }
   &__amount-fiat {
     font-size: var(--font-size-normal);
     color: var(--text-color-text-secondary);
   }
 }
 .organization {
   &__description {
     padding-bottom: 15px;
     margin-bottom: 15px;
     border-bottom: 1px solid  var(--border-border-input-primary-default);
     &-title {
       color: var(--text-text-primary, #000);
       font-size: 15px;
       font-weight: 600;
     }
     &-text {
       color: var(--text-color-text-secondary, #8B81A0);
       font-size: 15px;
       font-weight: 500;
       margin-bottom: 0;
     }
   }
   &__data {
     display: flex;
     align-items: center;
     justify-content: space-between;
   }
 }

 @media (max-width: 768px){
   .details-block {
     display: unset;
   }
 }
 .organization__data {
   .organization__description-title {
     margin-bottom: 0;
   }
 }
</style>
