<template>
  <div class="qr-wrapper" :class="{'error': ['expired', 'not_paid'].includes(status.value) && timeIsOver, success: timeIsOver && ['overpaid', 'fully_paid', 'processed'].includes(status.value), partial: timeIsOver && ['partially_paid'].includes(status.value) }">
    <div class="banner">
      <div
        v-if="['init', 'pending', 'partial'].includes(status.value) || !timeIsOver"
        id="canvas"
        class="banner__code"
      />
      <div v-if="['expired', 'not_paid'].includes(status.value) && timeIsOver">
        <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.1" cx="85" cy="85" r="85" fill="var(--ocp-0-100-error-error40)"/>
          <circle opacity="0.2" cx="85" cy="85" r="58" fill="var(--ocp-0-100-error-error40)"/>
          <circle cx="85" cy="84.9998" r="36" fill="var(--ocp-0-100-error-error40)"/>
          <path d="M76.0681 75.0681C76.6173 74.519 77.5077 74.519 78.0569 75.0681L85.5 82.5113L92.9431 75.0681C93.4923 74.519 94.3827 74.519 94.9319 75.0681C95.481 75.6173 95.481 76.5077 94.9319 77.0569L87.4887 84.5L94.9319 91.9431C95.481 92.4923 95.481 93.3827 94.9319 93.9319C94.3827 94.481 93.4923 94.481 92.9431 93.9319L85.5 86.4887L78.0569 93.9319C77.5077 94.481 76.6173 94.481 76.0681 93.9319C75.519 93.3827 75.519 92.4923 76.0681 91.9431L83.5113 84.5L76.0681 77.0569C75.519 76.5077 75.519 75.6173 76.0681 75.0681Z" fill="white"/>
        </svg>
        <p class="error">
          {{ $t('new_checkout.time-is-over') }}
        </p>
      </div>
      <div v-else-if="['overpaid', 'fully_paid', 'processed'].includes(status.value)">
        <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.1" cx="85" cy="85" r="85" fill="var(--ocp-status-success-success-primary)"/>
          <circle opacity="0.2" cx="85" cy="85" r="58" fill="var(--ocp-status-success-success-primary)"/>
          <circle cx="85" cy="84.9998" r="36" fill="var(--ocp-status-success-success-primary)"/>
          <path d="M92.852 76.9772C93.6757 76.1534 95.0113 76.1534 95.8351 76.9772C96.6487 77.7909 96.6587 79.1039 95.865 79.9298L84.6369 93.9649C84.6207 93.9851 84.6034 94.0045 84.5851 94.0228C83.7613 94.8466 82.4257 94.8466 81.602 94.0228L74.1588 86.5797C73.3351 85.7559 73.3351 84.4203 74.1588 83.5966C74.9826 82.7728 76.3182 82.7728 77.1419 83.5966L83.03 89.4846L92.796 77.0403C92.8134 77.0182 92.8321 76.9971 92.852 76.9772Z" fill="white"/>
        </svg>
        <p class="success">
          {{ $t('new_checkout.payment-successful') }}
        </p>
      </div>
      <div v-else-if="timeIsOver && ['partially_paid'].includes(status.value)">
        <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.1" cx="95" cy="95" r="95" fill="var(--ocp-status-medium-medium-orange-primary)"/>
          <circle opacity="0.2" cx="95" cy="95" r="65" fill="var(--ocp-status-medium-medium-orange-primary)"/>
          <circle cx="95" cy="95" r="40" fill="var(--ocp-status-medium-medium-orange-primary)"/>
          <path d="M92 103.875C92 102.149 93.3991 100.75 95.125 100.75C96.8509 100.75 98.25 102.149 98.25 103.875C98.25 105.601 96.8509 107 95.125 107C93.3991 107 92 105.601 92 103.875Z" fill="white"/>
          <path d="M92.3061 85.1095C92.1396 83.4446 93.447 82 95.1202 82C96.7933 82 98.1007 83.4446 97.9342 85.1095L96.8382 96.0703C96.7499 96.9529 96.0072 97.625 95.1202 97.625C94.2332 97.625 93.4905 96.9529 93.4022 96.0703L92.3061 85.1095Z" fill="white"/>
        </svg>
        <p class="partial">
          {{ $t('new_checkout.statuses.partially_paid') }}
        </p>
      </div>


    </div>
    <div v-if="!timeIsOver" class="bottom-info">
      <currency-img
          class="bottom-info__currencyImg"
        :currency="orderCurrency"
      />
      <span class="currency">
        {{ orderCurrency }}
      </span>
      <span class="network">
        - {{ shortNetworkName }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { checkoutOrderData } from "@/entries/checkout/mixins/checkout-order-data";
import QRCodeStyling from "qr-code-styling";
import debounce from "lodash/debounce"


export default {
  name: "QR-block",
  components: {
    CurrencyImg: () => import(/* webpackChunkName: "entries/checkout/components/CurrencyImg" */ '@/entries/checkout/components/CurrencyImg'),
  },
  data() {
    return {
      width: 0,
      productName: import.meta.env.VITE_PRODUCT_NAME
    }
  },
  mixins: [checkoutOrderData],
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    timeIsOver: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    sizeCode() {
      this.createQR()
    },
    themeStatus() {
      this.createQR()
    }
  },
  computed: {
    ...mapState(['themeStatus']),

    colorQrCode() {

      const lightBg = this.productName === "Apollopayment" ? "#f1f4f9" : "#f9f5ff"
      const darkBg = this.productName === "Apollopayment" ? "#1a2028" : "#2a2a2a"

      if(this.themeStatus) {
        return {
          bg: darkBg,
          code: '#ffffff',
        };
      }

      return {
        bg: lightBg,
        code: '#000000',
      };
    },
    shortNetworkName({ orderNetwork }) {
      let shortFromFullName = orderNetwork.match(/\((.+?)\)/)

      if (!shortFromFullName) {
        if (orderNetwork === 'Fantom') {
          shortFromFullName = "FTM"
        } else if (orderNetwork === 'Solana') {
          shortFromFullName = "SOL"
        } else {
          shortFromFullName = orderNetwork
        }
      } else {
        shortFromFullName = shortFromFullName[1]
      }

      return shortFromFullName
    },
    sizeCode() {
      return '220';
    },
    debounceOnResize: ({ onResize }) => debounce(onResize, 100),
  },
  created() {
    this.debounceOnResize()

    window.addEventListener(
      'resize',
      this.debounceOnResize,
      { passive: true }
    )
  },
  mounted() {
    setTimeout(() => {
      this.createQR()
    }, 500)
  },
  methods: {
    createQR() {
      this.qrCode(this.colorQrCode);
    },
    qrCode(color) {
      const { sizeCode } = this

      const qrCode = new QRCodeStyling({
        width: sizeCode,
        height: sizeCode,
        margin: 0,
        type: "svg",
        data: this.orderAddress,
        dotsOptions: {
          color: color.code,
          type: "extra-rounded",
        },
        backgroundOptions: {
          color: color.bg,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 0,
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: color.code,
        },
      });

      const canvas = document.getElementById("canvas")

      if (canvas) {
        qrCode.append(canvas);

        if (document.getElementById('canvas').children.length > 1) {
          document.getElementById('canvas').children[0].remove();
        }
      }
    },
    getClientWidth () {
      if (typeof document === 'undefined') return 0
      return Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
      )
    },
    onResize () {
      this.width = this.getClientWidth()
    }
  },
}
</script>

<style lang="scss" scoped>

  .qr-wrapper {
    padding: 20px 30px;
    background: var(--bg-background-disabled);
    border-radius: 10px;
    .banner {
      display: flex;
      align-items: center;
      justify-content: center;

      height: calc(100% - 55px);

      width: 100%;

      overflow: hidden;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bottom-info {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      margin-top: 10px;

      .currency {
        font-weight: 600;
        margin: 0 5px 0 8px;
        color:  var(--text-text-primary);;
      }
      .network {
        font-weight: 500;
        font-size: 16px;
        color: var(--text-color-text-secondary);
      }
    }
  }

  .error {
    background: var(--ocp-0-100-error-error90, #F9DEDC);
    color: var(--ocp-0-100-error-error40, #B04444);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    p {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  .success {
    color: var(--ocp-status-success-success-primary, #44B070);
    background: var(--ocp-status-success-success-bg, #DCF9E8);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    p {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  .partial {
    color: var(--ocp-status-medium-medium-orange-primary, #E4A062);
    background: var(--ocp-status-medium-medium-orange-bg, #F9EADC);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    p {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  .bottom-info__currencyImg {
    width: 24px;
    height: 24px;
  }
</style>
