<script>

  import DashboardContent from "@/entries/checkout/components/Dashboard/DashboardContent.vue";
  import {checkoutOrderData} from "@/entries/checkout/mixins/checkout-order-data";
  import NotFound from "@/entries/checkout/components/NotFound.vue";
  import {mapState} from "vuex";

  export default {
    name: "NewDashboard",
    components: {NotFound, DashboardContent},
    mixins: [ checkoutOrderData ],
    computed: {
      ...mapState({
        hasOrderData: state => state.fetched,
        themeStatus: state => state.themeStatus,
      }),
      failedOrder({ orderId }) {
        //проверяем что ордер с таким ID существует
        return !orderId
      },
    }
  }
</script>

<template>
<div v-if="hasOrderData" class="wrapper">
  <div v-if="!failedOrder" class="inner">
    <a v-if="orderReturnUrl" :href="orderReturnUrl" class="back">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.30859 2.68735C8.1143 2.59093 7.88042 2.60731 7.70261 2.72978L3.61928 5.54228C3.46598 5.64786 3.375 5.81834 3.375 6C3.375 6.18166 3.46598 6.35214 3.61928 6.45772L7.70261 9.27022C7.88042 9.39269 8.1143 9.40907 8.30859 9.31265C8.50288 9.21624 8.625 9.0232 8.625 8.8125V3.1875C8.625 2.9768 8.50288 2.78376 8.30859 2.68735Z" fill="#8B81A0"/>
      </svg>
      {{ $t('new_checkout.back_to_shop') }}
    </a>
    <div class="content">
      <DashboardContent :orphans-arr="orphans" />
    </div>
    <div class="id">ID: {{ orderId }}</div>
  </div>
  <not-found v-else/>
</div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.id {
  color: var(--text-color-text-secondary, #8B81A0);
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.back {
  color: var(--text-color-text-secondary, #8B81A0);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  text-decoration: none;
}
.inner {
  padding: 0 10px;
}
</style>
