export const ee = {
    'invoice': {
        'invoice': 'Tilinumero:',
        'sum-invoice': 'Tilin summa:',
        'select-invoice': 'Valitse maksuvaluutta',
        'coin-network': 'Valuutta ja verkko:',
        'rate': 'Hyvin:',
        'sum-payment': 'Maksun määrä:',
        'button': 'Maksu',
        'search': 'Otsing',
    },
    'checkout': {
        'shop': 'Skoor',
        'payment': 'Makse',
        'back_to_the_store': 'Tagasi poodi',
        'to_make_a_payment': 'Makse tegemiseks saatke «{orderCurrency}» võrgus «{orderAlias}» määratud aadressile',
        'payment_completed': 'Makse sooritatud',
        'the_payment_is_invalid': 'Makse ei kehti',
        'payment_rejected': 'Makse tagasi lükatud',
        'payment_pending': 'Makse töötlemisel',
        'payment_init': 'Makse ootel',
        'lifetime_has_expired': 'Makse eluiga on aegunud. Ärge saatke münte määratud aadressile.',
        'contact_the_store_owner': 'Makse eluiga on aegunud. Ärge saatke münte määratud aadressile. Müntide tagastamiseks või maksmise jätkamiseks võtke ühendust poe omanikuga',
        'order': 'Telli',
        'to_be_paid': 'Maksma',
        'received': 'Saadud:',
        'description': 'Kirjeldus:',
        'before_the_expiration_of_the_order': 'Makse kehtivusaeg:',
        'network': 'Net:',
        'payment_address': 'Makseaadress:',
        'payment_address_not_found': 'Makseaadressi ei leitud',
        'tag': 'Silt:',
        'contract_address': 'Lepingu aadress:',
        'send_to_this_address_only': 'Saada ainult sellele aadressile',
        'make_sure_the_network_is_correct': 'Kontrollige, kas võrk on õige:',
        'network_is_correct': 'Kontrollige, kas võrk on õige:',
        'contract_address_matches': 'Veenduge, et lepingu aadress ühtiks',
        'payment_page': 'Makseleht',
        'the_address_was_copied': 'Aadressi kopeerimine õnnestus',
        'the_tag_was_copied': 'Silt on kopeeritud',
        'invoice_was_successfully_paid': 'Tellimus on edukalt tasutud',
        'the_invoice_was_paid': 'Tellimuse eest maksti',
        'important_info': 'Oluline teave',
        'the_contract_address_does_not_exist_on_this_network': 'Lepingu aadressi selles võrgus ei ole',
        'error': 'Viga',
        'time_hours': 'tundi',
        'time_minute': 'minutit',
        'time_second': 'sekundžių',
        'sellers_address': 'Müüja aadress on kinnitatud',
        'stored_table': 'Siin on sissetulevate tehingute ajalugu',
        'history_table': 'Sissetulevate tehingute ajalugu',
        'full_info': 'Kuva kõik üksikasjad',
        'table_date': 'Kuupäev',
        'table_sum': 'Summa',
        'table_value': 'Valuuta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Summa ja kuupäev',
        'payment_partially_paid': 'Osaliselt makstud',
        'paid_timer': 'Makstud',
        'expired_timer': 'Makse on aegunud',
    },
    'new_checkout': {
        'details': {
            'title': 'Üksikasjad',
            'order': 'Tellimus',
            'date': 'Kuupäev',
            'description': 'Kirjeldus',
        },
        'notification': 'Palun saatke sellele aadressile ainult <b>{orderCurrency}</b> žetoone <b>{orderNetwork}</b> võrku.',
        'payment_address': 'Makseaadress',
        'verified_notification': "Müüja aadress on kontrollitud",
        'paid_amount': "Tasuline summa",
        'timer_notifications': {
            'remaining_time': 'Järelejäänud aeg maksmiseks:',
            'completed': 'Makse on edukalt lõpule viidud',
            'expired': 'Makse eluaeg aegus',
            'dont_make_transactions': "Ärge tehke selle tellimusega tehinguid!"
        },
        'statuses': {
            'not_paid': 'Maksmata',
            'overpaid': 'Üle maksmata',
            'partially_paid': 'Osaliselt tasustatud',
            'fully_paid': 'Täielikult tasustatud',
        },
        'history': {
            'title': 'Maksete ajalugu',
            'columns': {
                'date': 'Kuupäev',
                'amount': 'Summa',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Kõik teie määratud aadressile saadetud tehingud on selles aknas saadaval.',
            'ok': 'Okei'
        },
        'cancel': 'Tühistama',
        'back_to_shop': 'Tagasi poodi'
    },
    'new_invoice': {
        'details': {
            title: 'Üksikasjad',
            invoice: 'Arve',
            date: 'Kuupäev',
            description: 'Kirjeldus'
        },
        'amount': 'Summa',
        'btn_label': 'Jätka',
        'btn_label_empty': 'Vali valuuta',
        'cancel': 'Tühistama',
        'search': 'Otsing...',
        'orphan': {
            'title': 'Orb tehing',
            'description': 'Üks selle tellimuse tehingutest sisaldab valet võrku või valuutat.',
            'contact': 'Võtke ID-ga ühendust tehnilise toega',
        },
        'time-is-over': 'Aeg on läbi',
        'payment-successful': 'Makse sooritamine õnnestus',
        'partial-desc': 'Summa on osaliselt tasutud. ülejäänud summa tuleb tasuda eduka makse eest.',
        'back_to_shop': 'Tagasi poodi',
        'choose-network': 'Valige võrk'
    },
    'languages': {
        'select': 'Vali keel'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Lehte ei leitud',
            'page_not_found_text': 'Tundub, et me ei leia lehte, mida otsite',
        }
    }
};
