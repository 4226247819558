import { mapState } from "vuex";
import BigNumber from "bignumber.js";

export const checkoutOrderData = {
    computed: {
        ...mapState({
            orderId(state) {
                return state.order.id;
            },
            orderCurrency(state) {
                return state.order.currency;
            },
            orderAddress(state) {
                return state.order.address;
            },
            orderNetwork(state) {
                return state.order.network;
            },
            orderAlias(state) {
                return state.order.currencyAlias;
            },
            orderNetworkName(state) {
                return state.order.networkName;
            },
            orderTag(state) {
                return state.order.tag;
            },
            orderCreatedAt(state) {
                return state.order.createdAt;
            },
            orderExpiresAt(state) {
                return state.order.expiresAt;
            },
            orderReceived(state) {
                let number = new BigNumber(state.order.received).decimalPlaces(8);
                return number.toFixed();
            },
            orderAmount(state) {
                return Number(state.order.amount);
            },
            orderAmountUSD(state) {
                return state.order.amountUSD;
            },
            orderStatus(state) {
                return state.order.status;
            },
            orderMerchantOrder(state) {
                return state.order.order;
            },
            orderMerchantDescription(state) {
                return state.order.description;
            },
            orderReturnUrl(state) {
                return state.order.returnUrl;
            },
            orderBuyUrl(state) {
                return state.order.buyUrl;
            },
            orderContract(state) {
                return state.order.contract;
            },
            transactions(state) {
                return state.order.transactions;
            },
            organizationName(state) {
                return state.order.organizationName;
            },
            organizationLogoUrl(state) {
                return state.order.organizationLogoUrl;
            },
            orphans(state) {
                return state.order.orphans;
            }
        }),
    },
};
