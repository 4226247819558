import axios from "axios";

import {ORDER_STATUS_ENUM} from "@/common/constants/orders-status-enum";

export const fetchOrder = async ({state, commit}) => {
    if (state.fetching) {
        return;
    }
    if (state.order.status === ORDER_STATUS_ENUM.PROCESSED || state.order.status === ORDER_STATUS_ENUM.ERROR || state.order.status === 'expired') {
        return;
    }

    commit('setFetching', true);

    let orderId = window.location.pathname.match(/\/([a-zA-Z0-9-]+)$/);

    if(!(orderId && orderId.length === 2 && typeof orderId[1] === 'string' && orderId[1].length === 36)) {
        commit('setFetching', false);
        commit('setFetched', true);

        return;
    }

    orderId = orderId[1];

    try {
        const orderResponse = await axios.get('/public-api/checkout/' + orderId);

        const {code, result} = orderResponse.data;

        if (code === 0) {
            commit('setOrder', result);
        }
    } catch (e) {
        console.error('Fail fetch order data', e);
    } finally {
        commit('setFetching', false);
        commit('setFetched', true);
    }
};
