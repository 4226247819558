export const de = {
    'invoice': {
        'invoice': 'Konto-Nr:',
        'sum-invoice': 'Die Höhe der Rechnung:',
        'select-invoice': 'Zahlungswährung auswählen',
        'coin-network': 'Währung und Netzwerk:',
        'rate': 'Kurs:',
        'sum-payment': 'Zahlungsbetrag:',
        'button': 'Zahlung',
        'search': 'Suche',
    },

    'checkout': {
        'shop': 'Shop',
        'payment': 'Zahlung',
        'back_to_the_store': 'Zurück zum Shop',
        'to_make_a_payment': 'Um eine Zahlung vorzunehmen, senden Sie«{orderCurrency}» an das Netzwerk «{orderAlias}» an die angegebene Adresse',
        'payment_completed': 'Zahlung ausgeführt',
        'the_payment_is_invalid': 'Zahlung ist nicht gültig',
        'payment_rejected': 'Zahlung abgelehnt',
        'payment_pending': 'Zahlung in Bearbeitung',
        'payment_init': 'Zahlung ausstehend',
        'lifetime_has_expired': 'Die Laufzeit der Zahlung ist abgelaufen. Senden Sie keine Münzen an die angegebene Adresse.',
        'contact_the_store_owner': 'Die Laufzeit der Zahlung ist abgelaufen. Senden Sie keine Münzen an die angegebene Adresse. Wenden Sie sich an den Geschäftsinhaber, um eine Rückerstattung zu erhalten oder weiter zu bezahlen.',
        'order': 'Bestellung',
        'to_be_paid': 'Zu zahlen',
        'received': 'Empfangen:',
        'description': 'Beschreibung:',
        'before_the_expiration_of_the_order': 'Zahlungsseite:',
        'network': 'Netzwerk:',
        'payment_address': 'Zahlungsfrist:',
        'payment_address_not_found': 'Zahlungsadresse nicht gefunden',
        'tag': 'Tag:',
        'contract_address': 'Vertragsadresse:',
        'send_to_this_address_only': 'Nur an diese Adresse senden',
        'make_sure_the_network_is_correct': 'Stellen Sie sicher, dass das Netzwerk korrekt ist:',
        'network_is_correct': 'Stellen Sie sicher, dass das Netzwerk korrekt ist:',
        'contract_address_matches': 'Stellen Sie sicher, dass die Vertragsadresse übereinstimmt',
        'payment_page': 'Zahlungsseite',
        'the_address_was_copied': 'Adresse erfolgreich kopiert',
        'the_tag_was_copied': 'Der Tag wurde kopiert',
        'invoice_was_successfully_paid': 'Der Haftbefehl wurde erfolgreich bezahlt',
        'the_invoice_was_paid': 'Der Optionsschein wurde in Höhe von',
        'important_info': 'Wichtige Informationen',
        'the_contract_address_does_not_exist_on_this_network': 'Die Vertragsadresse existiert nicht in diesem Netzwerk',
        'error': 'Fehler',
        'time_hours': 'Stunden',
        'time_minute': 'Minuten',
        'time_second': 'Sekunden',
        'sellers_address': 'Die Adresse des Verkäufers ist verifiziert',
        'stored_table': 'Hier wird der Verlauf der eingehenden Transaktionen angezeigt',
        'history_table': 'Historie eingehender Transaktionen',
        'full_info': 'Vollständige Details anzeigen',
        'table_date': 'Datum',
        'table_sum': 'Betrag',
        'table_value': 'Währung',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Betrag und Datum',
        'payment_partially_paid': 'Teilweise bezahlt',
        'paid_timer': 'Bezahlt',
        'expired_timer': 'Die Zahlung ist abgelaufen',
    },
    'new_checkout': {
        'details': {
            'title': 'Einzelheiten',
            'order': 'Befehl',
            'date': 'Datum',
            'description': 'Beschreibung',
        },
        'notification': 'Bitte senden Sie nur <b>{orderCurrency}</b> in das <b>{orderNetwork}</b> an diese Adresse.',
        'payment_address': 'Rechnungsadresse',
        'verified_notification': "Die Adresse des Verkäufers wurde verifiziert",
        'paid_amount': "Bezahlte Menge",
        'timer_notifications': {
            'remaining_time': 'Verbleibende Zeit zu zahlen',
            'completed': 'Die Zahlung wurde erfolgreich abgeschlossen',
            'expired': 'Die Zahlungsdauer lief ab',
            'dont_make_transactions': "Führen Sie für diese Bestellung keine Transaktionen durch!"
        },
        'statuses': {
            'not_paid': 'Nicht bezahlt',
            'overpaid': 'Überbezahlt',
            'partially_paid': 'Teilweise bezahlt',
            'fully_paid': 'Vollständig bezahlt',
        },
        'history': {
            'title': 'Zahlungsgeschichte',
            'columns': {
                'date': 'Datum',
                'amount': 'Menge',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Alle an die angegebenen Adresse gesendeten Transaktionen sind in diesem Fenster verfügbar.',
            'ok': 'OK'
        },
        'orphan': {
            'title': 'Verwaiste Transaktion',
            'description': 'Eine der Transaktionen dieser Bestellung enthält das falsche Netzwerk oder die falsche Währung.',
            'contact': 'Kontaktieren Sie den technischen Support mit der ID',
        },
        'time-is-over': 'Die Zeit ist um',
        'payment-successful': 'Zahlung erfolgreich',
        'partial-desc': 'Der Betrag wurde teilweise bezahlt. Bei erfolgreicher Zahlung muss der Restbetrag beglichen werden.',
        'cancel': 'Stornieren',
        'back_to_shop': 'Zurück zum Einkaufen'
    },
    'new_invoice': {
        'details': {
            title: 'Einzelheiten',
            invoice: 'Rechnung',
            date: 'Datum',
            description: 'Beschreibung'
        },
        'amount': 'Menge',
        'btn_label': 'Weitermachen',
        'btn_label_empty': 'Währung wählen',
        'cancel': 'Stornieren',
        'search': 'Suchen...',
        'back_to_shop': 'Zurück zum Einkaufen',
        'choose-network': 'Wählen Sie Netzwerk'
    },
    'languages': {
        'select': 'Sprache auswählen'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Seite nicht gefunden',
            'page_not_found_text': 'Wir können scheinbar die Seite, die Sie suchen, nicht finden',
        }
    }
};
