<template>
<div
    class="copy-wrapper"
>
  <svg
    v-if="showCopyIcon"
    @click.stop="copyClipboard"
    class="clipboard-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
      :fill="color || (themeStatus ? '#7E7E7E' : '#8B81A0')"
    />
  </svg>
  <svg
    v-else
    class="clipboard-icon"
    width="16"
    height="16"
    viewBox="8 8 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" :stroke="color || '#97C582'" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</div>

</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "copy",
    computed: {
      ...mapState('app', ['theme']),
      themeStatus () {
        if (!this.theme) {
          let theme = localStorage.getItem('data-theme-private');

          return theme === 'dark';
        }
        return this.theme
      }
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: undefined,
      }
    },
    data() {
      return {
        showCopyIcon: true
      }
    },
    methods: {
      async copyClipboard() {
        const { value } = this;
        await navigator.clipboard.writeText(value)

        this.showCopyIcon = false;
        setTimeout( () => this.showCopyIcon = true, 1000);
      },
    },
  }
</script>

<style scoped lang="scss">
.copy-wrapper {
  display: flex;
  align-items: center;

  width: 16px;
  height: 16px;

  cursor: pointer;
  margin: 0 10px;

  position: relative;

  .clipboard-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
