export const ORDER_STATUS_ENUM = {
    INIT: 'init', // платеж создан
    ERROR: 'error', // ошибка
    PROCESSED: 'processed', // когда деньги зашли полностью
    PENDING: 'pending', // платеж частично оплачен
    REFUND: 'refund', //  платеж возварщен удалить
    EXPIRED: 'expired', // платеж истек
    PARTIAL: 'partial', // платеж частично оплачен и время истекло
    OVERPAID: 'overpaid',
};
