import Vue from 'vue';
import moment from "moment";

export const getFormatDate = (date, format = 'DD.MM.YYYY') => {
    return moment(date).format(format);
};

export const getFormatDateTime = (date, format = 'DD.MM.YYYY HH:mm') => {
    return moment(date).format(format);
};

export const getTruncatedString = (txt, truncLength = 4) => {
    return (txt && txt.length > truncLength * 2) ? `${txt.slice(0, truncLength)}****${txt.slice(-truncLength)}` : txt
}

Vue.filter('getTruncatedString', getTruncatedString);
Vue.filter('getFormatDate', getFormatDate);