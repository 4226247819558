export const mutations = {
    setFetching(state, value) {
        state.fetching = value;
    },
    setFetched(state, value) {
        state.fetched = value;
    },
    setOrder(state, order) {
        for(const [key, value] of Object.entries(order)) {
            state.order[key] = value;
        }
    },

    changeThemeStatus(state, payload) {
        state.themeStatus = payload;
    },

    changeLangMutations: (state, lang) => {
        state.lang = lang;
    },

    changeToggle(state, value) {
        state.toggle = value;
    },

    setModal(state, data = {}) {
        const {
            name = null,
            payload = {},
        } = data;

        state.openedModal = name;
        state.openedModalPayload = payload;
    }
};
