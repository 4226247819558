<script>
  export default {
    name: "PartiallyPaymentPopup",
    data() {
      return {
        showPopup: false
      }
    },
  }
</script>

<template>
<div class="partially__wrapper">
  <div class="partially__content" v-if="showPopup">
    <div class="partially__content-title">
      {{ $t('new_checkout.statuses.partially_paid') }}
    </div>
    <div class="partially__content-desc">
      {{ $t('new_checkout.partial-desc') }}
    </div>
    <div class="triangle"></div>
  </div>
  <div class="partially__trigger"
       @mouseover="showPopup = true"
       @mouseleave="showPopup = false"
  >
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.54766 0.176476C4.64143 0.0827077 4.7686 0.0300293 4.90121 0.0300293H11.1144C11.247 0.0300293 11.3742 0.0827077 11.468 0.176476L15.8614 4.56987C15.9551 4.66364 16.0078 4.79082 16.0078 4.92343V11.1366C16.0078 11.2692 15.9551 11.3964 15.8614 11.4902L11.468 15.8836C11.3742 15.9774 11.247 16.03 11.1144 16.03H4.90121C4.7686 16.03 4.64143 15.9774 4.54766 15.8836L0.154259 11.4902C0.0604909 11.3964 0.0078125 11.2692 0.0078125 11.1366V4.92343C0.0078125 4.79082 0.0604909 4.66364 0.154259 4.56987L4.54766 0.176476Z" fill="var(--ocp-status-medium-medium-orange-primary)"/>
      <path d="M7.01562 11.0601C7.01562 10.5078 7.46334 10.0601 8.01562 10.0601C8.56791 10.0601 9.01562 10.5078 9.01562 11.0601C9.01562 11.6123 8.56791 12.0601 8.01562 12.0601C7.46334 12.0601 7.01562 11.6123 7.01562 11.0601Z" fill="white"/>
      <path d="M7.11359 5.0551C7.06031 4.52234 7.47867 4.06006 8.01408 4.06006C8.54949 4.06006 8.96786 4.52234 8.91458 5.0551L8.56384 8.56254C8.53559 8.84498 8.29793 9.06006 8.01408 9.06006C7.73024 9.06006 7.49258 8.84498 7.46433 8.56254L7.11359 5.0551Z" fill="white"/>
    </svg>
  </div>
</div>
</template>

<style scoped lang="scss">
.partially__wrapper {
  margin-right: 13px;
}
.partially__trigger {
  position: absolute;
}
.partially__content {
  position: absolute;

  transform: translate(-69%,-115%);
  padding: 12px 13px;
  border: 1px solid var(--ocp-status-medium-medium-orange-primary);
  background: var(--ocp-status-medium-medium-orange-bg);
  border-radius: 8px;
  width: 256px;
  &-title {
    color: var(--ocp-status-medium-medium-orange-primary, #E4A062);
    font-size: 15px;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 2px;
  }
  &-desc {
    color: var(--text-text-primary, #000);
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
  }
  .triangle {
    display: block;
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -8px;
    right: 63px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 0.25em;
  }
}
</style>
