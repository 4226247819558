<script>

  import Copy from "@/public/components/common/copy.vue";
  import ClipboardCopy from "@/entries/checkout/components/ClipboardCopy.vue";

  export default {
    name: "OrphanBlock",
    components: {ClipboardCopy, Copy},
    props: {
      orphans: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      id () {
        if (!this.orphans.length || !this.orphans[0]) {
          return ''
        }
        return `${this.orphans[0].id.substring(0, 8)}...${this.orphans[0].id.substr(-8)}`
      },
      tx () {
        if (!this.orphans.length || !this.orphans[0].tx) {
          return ''
        }
        return `${this.orphans[0].tx.substring(0, 8)}...${this.orphans[0].tx.substr(-8)}`
      }
    }
  }
</script>

<template>
<div class="orphan">
  <div class="title">{{ $t('new_checkout.orphan.title') }}</div>
  <div class="desc">{{ $t('new_checkout.orphan.description') }}</div>
  <div class="contact">{{ $t('new_checkout.orphan.contact') }}:</div>
  <div class="id">
    ID: {{ id }} <ClipboardCopy :value="orphans[0].id" />
  </div>
  <div class="id">
    TX hash: {{ tx }} <ClipboardCopy v-if="orphans[0].tx" :value="orphans[0].tx" />
  </div>
</div>
</template>

<style scoped lang="scss">
.orphan {
  display: flex;
  width: 300px;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  background: var(--ocp-0-100-error-error90, #F9DEDC);
  margin-bottom:  15px;
}
.title {
  color: var(--ocp-0-100-error-error40, #B04444);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}
.desc {
  color: var(--ocp-0-100-error-error40, #D61A21);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}
.contact {
  color: var(--ocp-0-100-error-error40, #B04444);
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 15px;
}
.id {
  color: var(--ocp-0-100-error-error40, #B04444);
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .orphan {
    width: 100%;
  }
}
::v-deep {
  .clipboard-icon {
    path {
      fill: var(--ocp-0-100-error-error40);
      stroke: var(--ocp-0-100-error-error40);
    }
  }
  .clipboard-copied {
    path {
      stroke: var(--ocp-0-100-error-error40);
    }
  }
}
</style>
