export const it = {
    'invoice': {
        'invoice': 'Conto №:',
        'sum-invoice': 'L`importo della fattura:',
        'select-invoice': 'Selezionare la valuta di pagamento',
        'coin-network': 'Valuta e rete:',
        'rate': 'Corso:',
        'sum-payment': 'Importo del pagamento:',
        'button': 'Pagamento',
        'search': 'Ricerca',
    },
    'checkout': {
        'shop': 'Negozio',
        'payment': 'Pagamento',
        'back_to_the_store': 'Torna al negozio',
        'to_make_a_payment': 'Per effettuare un pagamento, inviare «{orderCurrency}» alla rete «{orderAlias}» all`indirizzo indicato',
        'payment_completed': 'Pagamento eseguito',
        'the_payment_is_invalid': 'Il pagamento non è valido',
        'payment_rejected': 'Pagamento rifiutato',
        'payment_pending': 'Pagamento in corso di elaborazione',
        'payment_init': 'In attesa di pagamento',
        'lifetime_has_expired': 'La durata del pagamento è scaduta. Non inviare monete all`indirizzo indicato.',
        'contact_the_store_owner': 'La durata del pagamento è scaduta. Non inviare monete all`indirizzo indicato. Contattare il negoziante per ottenere un rimborso o per continuare a pagare',
        'order': 'Ordine',
        'to_be_paid': 'Da pagare',
        'received': 'Ricevuto:',
        'description': 'Descrizione:',
        'before_the_expiration_of_the_order': 'Termine di pagamento:',
        'network': 'Rete:',
        'payment_address': 'Indirizzo di pagamento:',
        'payment_address_not_found': 'Indirizzo di pagamento non trovato',
        'tag': 'Tag:',
        'contract_address': 'Indirizzo del contratto:',
        'send_to_this_address_only': 'Inviare solo a questo indirizzo',
        'make_sure_the_network_is_correct': 'Assicurarsi che la rete sia corretta:',
        'network_is_correct': 'Assicurarsi che la rete sia corretta:',
        'contract_address_matches': 'Assicurarsi che l`indirizzo del contratto corrisponda',
        'payment_page': 'Pagina di pagamento',
        'the_address_was_copied': 'Indirizzo copiato con successo',
        'the_tag_was_copied': 'Il tag è stato copiato',
        'invoice_was_successfully_paid': 'Il mandato è stato pagato con successo',
        'the_invoice_was_paid': 'Il mandato è stato pagato per un importo di',
        'important_info': 'Informazioni importanti',
        'the_contract_address_does_not_exist_on_this_network': 'L`indirizzo del contratto non esiste su questa rete',
        'error': 'Errore',
        'time_hours': 'ore',
        'time_minute': 'minuti',
        'time_second': 'secondi',
        'sellers_address': 'L`indirizzo del venditore è verificato',
        'stored_table': 'Ecco la cronologia delle transazioni in entrata',
        'history_table': 'Cronologia delle transazioni in entrata',
        'full_info': 'Visualizza tutti i dettagli',
        'table_date': 'Data',
        'table_sum': 'Importo',
        'table_value': 'Valuta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Importo e data',
        'payment_partially_paid': 'Parzialmente retribuito',
        'paid_timer': 'Retribuito',
        'expired_timer': 'Il pagamento è scaduto',
    },
    'new_checkout': {
        'details': {
            'title': 'Dettagli',
            'order': 'Ordine',
            'date': 'Data',
            'description': 'Descrizione',
        },
        'notification': 'Si prega di inviare solo token <b>{orderCurrency}</b> sulla rete <b>{orderNetwork}</b> a questo indirizzo.',
        'payment_address': 'Indirizzo di pagamento',
        'verified_notification': "L'indirizzo del venditore è stato verificato",
        'paid_amount': "Importo pagato",
        'timer_notifications': {
            'remaining_time': 'Tempo rimanente per pagare:',
            'completed': 'Il pagamento è stato completato con successo',
            'expired': 'La durata del pagamento è scaduta',
            'dont_make_transactions': "Non effettuare transazioni su questo ordine!"
        },
        'statuses': {
            'not_paid': 'Non pagato',
            'overpaid': 'Pagato in eccesso',
            'partially_paid': 'Parzialmente pagato',
            'fully_paid': 'Pagato interamente',
        },
        'history': {
            'title': 'Storia dei pagamenti',
            'columns': {
                'date': 'Data',
                'amount': 'Quantità',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Tutte le transazioni inviate all\'indirizzo specificato saranno disponibili in questa finestra.',
            'ok': 'OK'
        },
        'orphan': {
            'title': 'Transazione orfana',
            'description': 'Una delle transazioni su questo ordine contiene la rete o la valuta sbagliata.',
            'contact': 'Contatta il supporto tecnico con l\'ID',
        },
        'time-is-over': 'Il tempo è finito',
        'payment-successful': 'Pagamento riuscito',
        'partial-desc': 'L\'importo è stato parzialmente pagato. affinchè il pagamento vada a buon fine dovrà essere versato l\'importo residuo.',
        'cancel': 'Annulla',
        'back_to_shop': 'Torna al negozio'
    },
    'new_invoice': {
        'details': {
            title: 'Dettagli',
            invoice: 'Fattura',
            date: 'Data',
            description: 'Descrizione'
        },
        'amount': 'Quantità',
        'btn_label': 'Continua',
        'btn_label_empty': 'Seleziona valuta',
        'cancel': 'Annulla',
        'search': 'Ricerca...',
        'back_to_shop': 'Torna al negozio',
        'choose-network': 'Scegli rete'
    },
    'languages': {
        'select': 'Seleziona la lingua'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Pagina non trovata',
            'page_not_found_text': 'Non riusciamo a trovare la pagina che stai cercando',
        }
    }
};
