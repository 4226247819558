<template>
<div class="clipboard-wrapper">
  <div
      style="display: flex; align-items: center; position: absolute;"
  >
    <svg
        v-if="showCopyIcon"
        @click="copyClipboard"
        class="clipboard-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
      <path
          d="M0 2C0 0.89543 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V12H2C0.895431 12 0 11.1046 0 10V2ZM2 1C1.44772 1 1 1.44772 1 2V10C1 10.5523 1.44772 11 2 11H10C10.5523 11 11 10.5523 11 10V2C11 1.44772 10.5523 1 10 1H2Z"
          fill="var(--bg-background-primary-purple)"
      />
    </svg>
    <svg v-else width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" class="clipboard-copied">
      <path d="M23.5361 13.2812L15.74 20.7188L11.8486 17" stroke="var(--bg-background-primary-purple)" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ClipboardCopy",
  computed: {
    ...mapState(['themeStatus']),
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      showCopyIcon: true
    }
  },
  methods: {
    async copyClipboard() {
      const { value } = this;
      await navigator.clipboard.writeText(value).then(() => {
        console.log(`Copied ${value} to clipboard`);
      }).catch((e) => {
        console.log('Error: ', e);
      });

      this.showCopyIcon = false;
      setTimeout( () => this.showCopyIcon = true, 1000);
    },
  },
}
</script>

<style scoped>
.clipboard-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  width: 16px;
  height: 16px;
}
  .clipboard-icon {
    cursor: pointer;
    margin: 0 10px;
  }
</style>
