<template>
  <div
    class="history-switcher-wrapper"
    :class="{ 'dark': themeStatus }"
    @click="showModal"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 23 21" fill="none">
      <g clip-path="url(#clip0_8259_59400)">
        <g>
          <path
            d="M5.83437 16.4454C9.31142 19.9224 14.9489 19.9224 18.4259 16.4454C21.9029 12.9684 21.9029 7.33094 18.4259 3.85388C14.9489 0.37683 9.31142 0.37683 5.83437 3.85388C4.09473 5.59353 3.22546 7.87396 3.22658 10.1541L3.22656 12.1282"
            :stroke="iconColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.24609 10.1494L3.22466 12.128L5.20323 10.1494"
            :stroke="iconColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.1406 6.19238V11.1388H16.087"
            :stroke="iconColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8259_59400">
          <rect width="22.5557" height="20.3001" :fill="iconColor"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "HistorySwitcher",
  data() {
    return {
      hover: false
    }
  },
  computed: {
    ...mapState(['themeStatus']),
    iconColor({ themeStatus, hover }) {
      return '#ffffff'
    }
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    showModal() {
      const { openModal } = this;

      openModal({
        name: 'CheckoutOrderHistory',
      });
    },
  },
}
</script>

<style lang="scss">
  .history-switcher-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 48px;
    min-height: 48px;

    background: var(--bg-background-primary-purple, #744DB2);
    border-radius: 0px 10px 10px 0px;
    padding: 15px;

    transition: all .3s ease;

    //&:hover {
    //  border: 1px solid #6750A4;
    //
    //  &.dark {
    //    border: 1px solid #ffffff;
    //  }
    //}


    cursor: pointer;
  }
</style>
