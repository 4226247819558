<template>
  <div class="notification-wrapper">
    <slot name="icon">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          style="min-width: 24px; min-height: 24px"
      >
        <path
            d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z"
            fill="var(--ocp-status-medium-medium-primary)"
        />
        <path
            d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z"
            fill="var(--ocp-status-medium-medium-primary)"
        />
        <path
            d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z"
            fill="var(--ocp-status-medium-medium-primary)"
        />
      </svg>
    </slot>
    <slot name="label">
      <div
        class="label"
        v-html="$t('new_checkout.notification', { orderCurrency: orderCurrency, orderNetwork: orderNetwork })"
      />
    </slot>

  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    orderCurrency: {
      type: String,
      default: ''
    },
    orderNetwork: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang="scss">
  .notification-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 5px;

    padding: 10px 17px;
    border-radius: 10px;

    background: var(--ocp-status-medium-medium-bg);

    .label {
      margin-left: 22px;
      font-size: 13px;
      font-weight: 500;
      color: var(--ocp-status-medium-medium-primary);
    }
  }
</style>
