import Vue from 'vue';

// import Page from '/public/pages/checkout/checkout.vue';
import Page from './page/checkout.vue';

import {checkoutStore} from "/public/pages/checkout/store";
import {i18n} from '/public/i18n';

new Vue({
    el: "#app",
    store: checkoutStore,
    render: h => h(Page),
    i18n,
});
