import {fetchOrder} from "./actions/fetchOrder";
import {changeLanguage} from "./actions/changeLanguage";
import {closeModal, openModal} from "./actions/modal";

export const actions = {
    fetchOrder: fetchOrder,
    changeLanguage: changeLanguage,
    openModal: openModal,
    closeModal: closeModal,
};
